@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Nunito", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html,
#root {
  @apply bg-gray-200;
  height: 100%;
  box-sizing: border-box;
}

header {
  background-color: rgba(255, 255, 255, 0.9);
  position: fixed;
  z-index: 2;
  width: 100%;
}

main {
  background-color: #fafbfc;
  line-height: 160%;
  color: #192a3d;
  font-size: 18px;
}

main a {
  text-decoration: underline;
}

main a:hover {
  color: #f094b0;
}

.mb-extra {
  padding-top: 7.5em;
}

.show-jumbo {
  background: url("./images/bg.jpg");
  background-color: rgb(240, 240, 240);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.logo {
  cursor: pointer;
  width: auto;
}

.carousel-wrapper {
  padding: 30px;
}

.arrow-left {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all ease 0.4s;
  cursor: pointer;
  opacity: 50%;
  height: 25px;
}

.arrow-right {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 50%;
  transition: all ease 0.4s;
  cursor: pointer;
  height: 25px;
}

.arrow-right:hover,
.arrow-left:hover {
  opacity: 100%;
}

.carousel-actions {
  width: 60px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.carousel-actions img {
  display: block;
}

.row {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  margin: -14px -14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

footer {
  background-color: #fff;
  font-size: 16px;
}

.padding-lg {
  @apply md:py-28 md:px-24 py-14 px-12;
}

.title {
  @apply font-playfair mt-2 mb-14 text-black;
  font-weight: 500;
  position: relative;
  z-index: 0;
  line-height: 140% !important;
}

.border-t-gray {
  border-color: rgb(59, 59, 59);
}

.border-t-1 {
  border-top-width: 1px;
}

.border-r-1 {
  border-right-width: 1px;
}

.title::before {
  content: url("./images/title-underline.png");
  position: absolute;
  bottom: -30px;
  z-index: -1;
}

.vertically-centered::before {
  left: 50%;
  transform: translate(-50%);
}

.btn-outline {
  letter-spacing: 0.15rem;
  text-transform: capitalize;
  font-size: 18px;
}

.subtitle {
  font-family: "Poppins", serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  display: block;
}

label {
  padding-left: 5px;
  margin-bottom: 5px;
  display: block;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 0.6rem;
  outline: none;
  background-color: rgb(255, 255, 255);
  border: 1px solid #dddddd;
  transition: 0.3s;
}

.form-control:focus {
  border: 1px solid #f1b2ba;
}

.hero-subtitle {
  font-family: "Poppins", serif;
  font-size: 32px;
}
/* 
.title::before {
  content: '';
  @apply block w-12 h-2 bg-pink-200 rounded-full mb-2;
} */

.menu-dialog-body {
  background-color: #fff;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  align-items: flex-start;
  width: 350px;
  transition: all 0.3s;
  z-index: 2;
  padding: 0.8em 0em 0em 2em;
}

.overlay {
  content: "";
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  visibility: visible;
  opacity: 0.5;
  transition: opacity 0.2s ease-in;
  z-index: 1; /* Add this */
  transition: all 0.3s;
}

.overlay-hide {
  opacity: 0;
  transition: all 0.3s;
}

.menu-dialog-hide {
  transform: translateX(400px);
  transition: all 0.3s;
}

.mobile-menu {
  display: none;
  font-size: 16px;
}

.menu-link {
  font-family: "Poppins", serif;
  cursor: pointer;
  border-radius: 0px !important;
  border-bottom: 4px solid rgba(255, 255, 255, 0);
  text-decoration: none;
  font-size: 1.1em;
  position: relative;
  height: 100%;
  align-items: center;
  display: flex;
}

.menu-link:hover,
.menu-link:active,
.menu-link:focus {
  background-color: transparent !important;
}

.menu-link:hover {
  color: #f094b0;
}

.menu-active {
  border-bottom: 4px solid #f094b0;
}

.menu {
  height: 100%;
}

.underline {
  border-bottom: 3px solid #f094b0;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}

.card-main {
  background-color: #fff;
  border-radius: 0px;
}

.card-col {
  @apply p-10;
  border-radius: 10px;
}

.card-col h3 {
  @apply text-5xl font-bold font-playfair mb-6;
  text-align: center;
}

.card-main::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 14px;
  height: 100%;
  background: linear-gradient(#fbdbd6, #bd928c);
}

.bg-rose {
  background-color: #f7e9e8;
}

.bg-beauty {
  background-color: #f094b0;
  border-color: #f094b0;
  color: #fff;
}

.bg-beauty:hover {
  /* background-color: #ffd9e3;
  border-color: #ffd9e3; */
  background-color: #202020;
  border-color: #202020;
}

.card-dark {
  background-color: #2b2b2b;
  border-radius: 0px;
  color: #fff !important;
}

.btn {
  font-family: "Poppins", serif;
  letter-spacing: 1px;
  font-weight: 400;
  border-radius: 0px;
  text-transform: uppercase;
  text-decoration: none !important;
  font-size: 16px;
  align-items: center;
  box-sizing: border-box;
  padding: 1.7em 2.7em;
  display: flex;
  flex-direction: column;
}

.btn-content {
  display: flex;
  box-sizing: border-box;
  clear: both;
}

.btn-beauty .hero-arrow path {
  fill: #000;
}

.btn-primary .hero-arrow path {
  fill: #000;
}

.btn-primary:disabled .hero-arrow path {
  fill: rgb(109, 109, 109);
}

/* .btn-beauty {
  background: linear-gradient(#fbdbd6, #bd928c);
  /* background: #fff !important;
  color: #000;
  transition: all ease 0.4s;
  border: none;
} */

.btn-beauty {
  background: transparent !important;
  border: 1px solid #fff !important;
  transition: all ease 0.2s;
  border: none;
  position: relative;
  overflow: hidden;
}

.btn-beauty::before {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 100%;
  transition: 0.2s;
  right: 100%;
}

.btn-beauty:hover:before {
  right: 0%;
}

.btn-beauty:hover {
  color: #000 !important;
}

.btn-beauty:active,
.btn-beauty:focus {
  border: 1px solid #fff !important;
}

.btn-beauty:hover .hero-arrow path {
  fill: #000;
}

.btn-primary:hover .hero-arrow path {
  fill: rgb(255, 255, 255);
}

.btn-primary {
  background: transparent !important;
  border: 1px solid #000 !important;
  color: #000;
  transition: all ease 0.2s;
  border: none;
  position: relative;
  overflow: hidden;
  font-weight: 500;
}

.btn-primary:disabled {
  background-color: rgb(189, 189, 189) !important;
  color: rgb(109, 109, 109);
  border-color: rgb(189, 189, 189) !important;
  cursor: not-allowed !important;
}

.btn-primary:hover {
  color: rgb(255, 255, 255);
}

.btn-primary::before {
  content: "";
  position: absolute;
  background-color: #202020;
  width: 100%;
  height: 100%;
  transition: 0.2s;
  right: 100%;
}

.btn-primary:hover:before {
  right: 0%;
}

.btn > p {
  position: relative;
  z-index: 1;
}

.btn-white-outlined {
  background: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.btn-white-outlined:hover {
  border: 1px solid rgba(255, 255, 255, 1);
}
.btn-dark {
  background-color: #202020;
  border-color: #202020;
}

.btn-beauty:hover,
.btn-beauty:active {
  background: linear-gradient(#fbdbd6, #fbdbd6);
  transition: all ease 0.4s;
}

.border-bottom-dotted {
  border-bottom: 1px dotted rgb(122, 122, 122);
}

.fill-beauty {
  fill: #856d51;
}

.text-beauty {
  color: #856d51;
}

.btn-beauty {
  background-color: #856d51;
  border-color: #856d51;
}

.btn-beauty:hover {
  background-color: #c9b39b;
  border-color: #c9b39b;
}

.bg-gray {
  background-color: #2b2b2b;
}

.hyphens-auto {
  hyphens: auto;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(85, 85, 85);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(49, 49, 49);
}

.box {
  position: relative;
  background: white;
  border-radius: 1em;
  z-index: 0;
}

.box:before {
  position: absolute;
  z-index: -1;
  content: "";
  top: 5px;
  left: 5px;
  bottom: 5px;
  right: 5px;
  background: linear-gradient(-45deg, #e81cff 0%, #40c9ff 100%);
  filter: blur(15px);
  transform: translate3d(0px, 10px, 0px);
  opacity: 0.6;
}

.box::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  border-radius: inherit;
}

.img-gradient {
  position: relative;
  z-index: 0;
  border-radius: 100%;
}

.img-gradient:before {
  border-radius: 100%;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(-45deg, #e81cff 0%, #40c9ff 100%);
  filter: blur(25px);
  transform: translate3d(0px, 10px, 0px);
  opacity: 0.6;
}

.img-gradient::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  border-radius: inherit;
}

.worker-image {
  height: 500px;
  width: 100%;
  object-fit: cover;
}

.worker {
  width: 100%;
  max-width: 600px;
}

.custom-menu {
  height: 100%;
}

.navbar {
  padding: 0px;
}

@media (max-width: 768px) {
  .custom-menu {
    display: none;
  }

  .mobile-menu {
    display: block;
  }

  .hero {
    padding: 15px;
  }

  .show-jumbo {
    background-position: center;
    height: 500px;
  }

  .navbar {
    padding-left: 15px;
    padding-right: 15px;
  }

  .title {
    line-height: 150%;
    hyphens: auto;
  }
}
